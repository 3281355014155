import logo from '../assets/images/pme-logo.svg';
import logo_scritta from '../assets/images/paolo_mereghetti.svg';

import Typewriter from "typewriter-effect";

function Home() {
  return (
    <div className="App">
      <header className="App-header">
        <img style={{ width: "400px" }} src={logo} className="App-logo" alt="logo" />
        <img style={{ paddingBottom: "30px" }} src={logo_scritta} className="App-logo-scritta" alt="logo 2" />

        <Typewriter
          // options={{ loop: true, }}

          onInit={(typewriter) => {

            typewriter.pauseFor(1000)
              .typeString("Curioso di scoprire di cosa mi occupo?")
              .pauseFor(1000)
              .deleteAll()
              .typeString("un attimo di pazienza e lo scoprirai...")
              .start()
              .pauseFor(5000);
          }}
        />
      </header>
    </div>
  );
}

export default Home;
