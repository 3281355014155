import React from 'react';
import Home from './component/Home';

import './assets/css/App.css';

import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={< Home />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
